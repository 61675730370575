.stripe-input .StripeElement  {
    box-shadow: none !important;
    font-size: 14px !important;
    display: block !important;
    width: 100% !important;
    height: 38px !important;
    background: #fff !important;
    box-shadow: 0 1px 2px #1018280d !important;
    border: 1px solid #d0d5dd !important;
    border-radius: 6px !important;
    box-sizing: border-box !important;
    background-color: transparent !important;
}

.StripeElement::placeholder .InputElement {
    color:  #757575 !important;
}

.promo-applied {
    width: max-content;
    border: 2px solid #70c270;
    background-color: #e1f4e1;
    border-radius: 5px;
}