.human-body {
  width: 207px;
  position: relative;
  padding-top: 240px;
  height: 260px;
  display: block;
  margin: 10px auto;
}

.human-body svg:hover,
.human-body svg:active,
.human-body svg:focus {
  cursor: pointer;
  outline: none;
}

.human-body svg:hover path {
  fill: #ff1616;
}

.active-svg-path {
  fill: #ff1616;
}

.human-body svg {
  position: absolute;
  left: 50%;
  fill: #57c9d5;
}

.human-body svg#head {
  margin-left: -28.5px;
  top: -6px;
}

.human-body svg#left-shoulder {
  margin-left: -53.5px;
  top: 69px;
}

.human-body svg#right-shoulder {
  margin-left: 13.5px;
  top: 69px;
}

.human-body svg#left-arm {
  margin-left: -78px;
  top: 112px;
}

.human-body svg#right-arm {
  margin-left: 38px;
  top: 112px;
  z-index: 10001;
}

.human-body svg#chest {
  margin-left: -43.5px;
  top: 88px;
}

.human-body svg#stomach {
  margin-left: -37.5px;
  top: 130px;
}

.human-body svg#left-leg {
  margin-left: -46.5px;
  top: 205px;
  z-index: 9999;
}

.human-body svg#right-leg {
  margin-left: 1.5px;
  top: 205px;
  z-index: 9999;
}

.human-body svg#left-hand {
  margin-left: -102.5px;
  top: 224px;
}

.human-body svg#right-hand {
  margin-left: 66.5px;
  top: 224px;
  z-index: 10000;
}

.human-body svg#left-foot {
  margin-left: -35.5px;
  top: 455px;
}

.human-body svg#right-foot {
  margin-left: 5.5px;
  top: 455px;
}

#area {
  display: block;
  width: 100%;
  clear: both;
  padding: 10px;
  text-align: center;
  font-size: 25px;
  font-family: Courier New;
  color: #a5a5a5;
}

#area #data {
  color: black;
}
