
.notificatio-height{
    max-height: 60vh;
    overflow-y: auto;
    
    
}
/* width */
.notificatio-height::-webkit-scrollbar {
    width: 8px;
    border-radius: 2rem;
}

/* Track */
.notificatio-height::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 2rem;
}

/* Handle */
.notificatio-height::-webkit-scrollbar-thumb {
    background: rgb(201, 201, 201); 
    border-radius: 2rem;
  }
  
  /* Handle on hover */
  .notificatio-height::-webkit-scrollbar-thumb:hover {
    background: rgb(146, 146, 146); 
  }
/*to truncate the message*/
  .truncate {
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .message-text {
    word-wrap: break-word;
    word-break: break-word;
  }
  .message-text a {
    color: blue;
    text-decoration: underline;
  }
  
  .message-text a:hover {
    color: darkblue;
  }
  
  /*for blinking */
  .notification-blink {
    animation: blink-animation 1s infinite;
    color: red;
 
  }
  
  @keyframes blink-animation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .notification-red {
    color: red;
  }
  
  