
.custom-multi-select {
    position: relative;
    width: 100%;
    /* max-width: 350px; */
  }
  
  .dropdown-header {
    display: flex;
    align-items: center;
    /* padding: 6px; */
  padding: 0px 8px;
    height: 37px;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    background-color: #fff;
  }
  
  .dropdown-header.disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
  }
  .dropdown-header:hover {
    border: 1px solid #ccc;
  }
  .placeholder {
    color: #aaa;
  }
  
  .selected-items {
    display: flex;
    flex-wrap: wrap;
    
    /* gap: 4px; */
  }
  
  .selected-item {
    background-color: #e0e7ff !important;
    border-radius: 12px;
    /* padding: px; */
    display: flex;
    align-items: center;
    padding: 0px;
  }
  
  .remove-item {
    margin-left: 4px;
    cursor: pointer;
  }
  
  .dropdown-arrow {
    margin-left: auto;
  }
  
  .dropdown-list {
    position: absolute;
    width: 100%;
    /* border: 1px solid #ddd; */
    background-color: #fff;
    border-radius: 4px;
    margin-top: 4px;
    /* max-height: 200px !important;
    overflow-y: scroll !important; */
    z-index: 9999;
  }
  
  .dropdown-item {
    padding: 8px;
    cursor: pointer;
  }
  
  .dropdown-item:hover,
  .dropdown-item.selected {
    background-color: #e0e7ff;
  }
  
  .no-options {
    padding: 8px;
    color: #aaa;
    text-align: center;
  }
  
  .error-message {
    color: red;
    font-size: 12px;
    margin-top: 4px;
  }
  
  .custom-multi-select .detailed-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    border: 1px solid #ccc;
    background-color: #fff;
    padding: 8px;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 10;
  }
  
  .custom-multi-select .detailed-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 0;
  }
  
  .custom-multi-select .more-items {
    cursor: pointer;
    margin-left: 4px;
  }
  .custom-multi-select .selected-items .selected-item,
.custom-multi-select .detailed-dropdown .detailed-selected-item {
  display: inline-flex;
  align-items: center;
  background-color: #e0e0e0;
  border-radius: 16px;
  padding: 4px 8px;
  margin: 2px;
  font-size: 0.9em;
}

.custom-multi-select .remove-item {
  margin-left: 8px;
  cursor: pointer;
  color: #888;
}

.custom-multi-select .more-items {
  cursor: pointer;
  margin-left: 4px;
}

.custom-multi-select .detailed-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 4px;
  border: 1px solid #ccc;
  background-color: #fff;
  padding: 8px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  z-index: 10;
}

.custom-multi-select .detailed-dropdown .detailed-selected-item {
  display: inline-flex;
  align-items: center;
  background-color: #e0e0e0;
  border-radius: 16px;
  padding: 4px 8px;
  margin: 2px;
  font-size: 0.9em;
}
.custom-multi-select .detailed-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  border: 1px solid #ccc;
  background-color: #fff;
  padding: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.custom-multi-select .detailed-dropdown .detailed-selected-item {
  display: inline-flex;
  align-items: center;
  background-color: #ddd;
  border-radius: 16px;
  padding: 4px 8px;
  margin: 2px;
  font-size: 0.9em;
}
