.truncateText {
    width: 70px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .tooltip {
    position: relative;
    display: inline-block;
    /* border-bottom: 1px dotted black; */
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    /* visibility: visible; */
    width: 150px;
    background: linear-gradient(90deg, #4B6CB7 0%, #293F6A 116.11%);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 3px 10px;
    margin-top: 1.5rem;
  
    /* Position the tooltip */
    position: fixed;
    z-index: 11;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }