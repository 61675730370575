@tailwind base;
@tailwind components;
@tailwind utilities;
[type="text"]:focus,
[type="email"]:focus,
[type="url"]:focus,
[type="password"]:focus,
[type="number"]:focus,
[type="date"]:focus,
[type="datetime-local"]:focus,
[type="month"]:focus,
[type="search"]:focus,
[type="tel"]:focus,
[type="time"]:focus,
[type="week"]:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  box-shadow: none;
}
@font-face {
  font-family: fontBoldAvenir;
  src: url(./assets/fonts/AvenirNextLTPro-Bold.otf);
}
@font-face {
  font-family: fontItalicAvenir;
  src: url(./assets/fonts/AvenirNextLTPro-It.otf);
}
@font-face {
  font-family: fontRegularAvenir;
  src: url(./assets/fonts/AvenirNextLTPro-Regular.otf);
}

@layer components {
  .transition-all-custom {
    transition: background-color ease-in-out 0.3s;
  }
  .focus-transition {
    transition: all 0.5s ease-in;
  }

  .icon-size {
    @apply rounded-md text-center h-4 w-4;
  }
  .icon-eye-wrapper {
    @apply text-yellow-400 hover:text-white border border-yellow-400 hover:border-yellow-500 hover:bg-yellow-400 hover:transition-all-custom p-[3px] rounded-md;
  }
  .icon-pencil-wrapper {
    @apply text-green-400 hover:text-white border border-green-400 hover:border-green-500 hover:bg-green-400 hover:transition-all-custom p-[3px] rounded-md;
  }
  .icon-delete-wrapper {
    @apply text-red-400 hover:text-white border border-red-400 hover:border-red-500 hover:bg-red-400 hover:transition-all-custom p-[3px] rounded-md;
  }
  .icon-download-wrapper {
    @apply text-blue-400 hover:text-white border border-blue-400 hover:border-blue-500 hover:bg-blue-400 hover:transition-all-custom p-[3px] rounded-md;
  }
  .icon-stopPlay-wrapper {
    @apply text-purple-400 hover:text-white border border-purple-400 hover:border-purple-500 hover:bg-purple-400 hover:transition-all-custom p-[3px] rounded-md;
  }
  .icon-filePdf-wrapper {
    @apply text-rose-400 hover:text-white border border-rose-400 hover:border-rose-500 hover:bg-rose-400 hover:transition-all-custom p-[3px] rounded-md;
  }
  .icon-print-wrapper {
    @apply text-gray-600 hover:text-white border border-gray-900 hover:border-black hover:bg-black hover:transition-all-custom p-[3px] rounded-md;
  }
  .icon-clone-wrapper {
    @apply text-gray-400 hover:text-white border border-gray-400 hover:border-gray-500 hover:bg-gray-400 hover:transition-all-custom p-[3px] rounded-md;
  }
  .icon-email-wrapper {
    @apply text-indigo-400 hover:text-white border border-indigo-400 hover:border-indigo-500 hover:bg-indigo-400 hover:transition-all-custom p-[3px] rounded-md;
  }
}

body {
  margin: 0;
  font-family: fontRegularAvenir, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Hide scrollbar in Chrome, Edge, and Safari */
/* body::-webkit-scrollbar {
  width: 0.1em;
  background-color: transparent;
} */

/* Hide scroll bar */
/* ::-webkit-scrollbar {display:none;} */

/* Hide scrollbars in Firefox */
/* * {
  scrollbar-width: none; /* For other browsers */
/* -moz-scrollbar-width: none; For Firefox */
/* }  */

/* Styling / Showing Scrollbar */
/* WebKit (Chrome, Safari, etc.) */
body::-webkit-scrollbar {
  width: 10px; /* Width of the vertical scrollbar */
  height: 10px; /* for horizontal scrollbar */
}

body::-webkit-scrollbar-track {
  background: #ffffff; /* Color of the track */
}

body::-webkit-scrollbar-thumb {
  /* background-color: #888; Color of the thumb */
  background: linear-gradient(90deg, #4b6cb7 0%, #293f6a 116.11%);
  border-radius: 5px; /* Rounded corners for the thumb */
}

/* Firefox */
@-moz-document url-prefix() {
  html,
  body {
    scrollbar-width: thin; /* Sets the width of the scrollbar to "thin" (auto width) */
    /* scrollbar-color: #888 #f1f1f1; Sets the color of the thumb (handle) and track */
    scrollbar-color: #4b6cb7 #ffffff; /* Sets the color of the thumb (handle) and track */
  }
}

/* body {
  scrollbar-width: thin; /* Set width of the scrollbar */
/* scrollbar-color: #888 #f1f1f1; Color of the thumb and track */
/* } */

/* :root {
  font-family: 'Vazir', sans-serif !important;
} */

.StripeElement {
  padding: 9.5px;
  box-shadow: 2px 2px 5px rgb(69 69 69 / 20%);
  border-radius: 8px;
  border: 0;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
}

.Toastify__toast {
  border-radius: 10px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.graph-width {
  margin-top: 25px;
  width: 100%;
  min-width: 1052px !important;
  border-radius: 8px;
}

.btn-report-incident {
  background: #38528b;
}

.bg-blue {
  background: #ecf0fd;
}

.text-blue {
  color: #02397a;
}

.logo {
  /*width: 206px;
  height: 64px;*/
  width: auto;
  height: auto;
  padding-top: 5px;
  padding-bottom: 5px;
}

.bg-gradient {
  background: linear-gradient(90deg, #4b6cb7 0%, #293f6a 116.11%);
}
.bg-gradient-ndisPurple {
  background: linear-gradient(90deg, #dc00e2 0%, #8c198f 116.11%);
}
.bg-gradient-danger {
  background: linear-gradient(90deg, #ff4242 0%, #d60707 116.11%);
}

.background-gradient {
  background: linear-gradient(90deg, #4b6cb7 0%, #293f6a 116.11%);
}
.background-gradient:hover {
  background: linear-gradient(30deg, #4b6cb7 0%, #293f6a 116.11%);
}

.bg-light-purple-gradient {
  /*background: linear-gradient(310.6deg, rgb(175, 104, 254) 6.8%, rgba(166, 0, 188, 0.66) 57.8%);*/
  background: #c0392b; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #c0392b,
    #8e44ad
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #c0392b,
    #8e44ad
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.bg-light-green-gradient {
  background: #4b6cb7; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #5b86e5,
    #4b6cb7
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #5b86e5,
    #4b6cb7
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.custom-box-shadow {
  box-shadow: 0 4px 0 #d5d5d5;
}

/*.bg-gradient:hover,*/
/*.bg-gradient:active,*/
/*.bg-gradient:focus {*/
/*  background: linear-gradient(30deg, #4b6cb7 0%, #293f6a 116.11%);*/
/*  transition: all 0.5s ease-in;*/
/*}*/

/*.bg-gradient-ndisPurple:hover,*/
/*.bg-gradient-ndisPurple:active,*/
/*.bg-gradient-ndisPurple:focus {*/
/*  background: linear-gradient(90deg, #8C198F 0%, #dc00e2 116.11%);*/
/*  transition: all 1s ease-in;*/
/*}*/
.bg-gradient-danger:hover,
.bg-gradient-danger:active,
.bg-gradient-danger:focus {
  background: linear-gradient(90deg, #d60707 0%, #ff4242 116.11%);
  transition: all 1s ease-in;
}

.bg-gray-900 {
  background: #101828 !important;
}

.bg-profile {
  background: rgba(255, 255, 255, 0.1);
}

.user-name {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #f9fafb;
}
.user-username {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #e5e7eb;
}
.sheet-user-name {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #344054;
}

.sheet-user-username {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
}

.navLink-title {
  color: #fcfcfd;
}

.navLink-icon {
  color: #fcfcfd;
}

.navLinksOthers {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}
/* ad  */
.start-time-input {
  width: 100% !important;
  border-bottom: 1px solid #eaecf0 !important;
  outline: none;
  border: navajowhite;
  background: transparent;
  border-radius: 0;
}
button:focus {
  outline: none;
}
.borderRed {
  border: 1px solid rgb(228, 69, 69) !important;
}

.textRed {
  color: rgb(228, 69, 69) !important;
}

.borderGreen {
  border: 1px solid rgb(34, 128, 34) !important;
}

#dropdown-date {
  display: flex;
}
#dropdown-year select {
  font-size: 12px;
  width: 80px;
  height: 36px;
  /* padding: 0 20px; */
  background: #fff;
  color: #101828;
  box-shadow: 0 1px 2px #1018280d;
  border: 1px solid #d0d5dd;
  border-radius: 6px;
  box-sizing: border-box;
}
#dropdown-month select {
  font-size: 12px;
  width: 100px;
  height: 36px;
  /* padding: 0 20px; */
  background: #fff;
  color: #101828;
  box-shadow: 0 1px 2px #1018280d;
  border: 1px solid #d0d5dd;
  border-radius: 6px;
  box-sizing: border-box;
}

#dropdown-day select {
  font-size: 12px;
  width: 50px;
  height: 36px;
  /* padding: 0 20px; */
  background: #fff;
  color: #101828;
  box-shadow: 0 1px 2px #1018280d;
  border: 1px solid #d0d5dd;
  border-radius: 6px;
  box-sizing: border-box;
}

/* WebKit-based browsers (e.g., Chrome and Safari) */
.custom-scroll::-webkit-scrollbar {
  width: 5px;
  height: 10px !important;
}

.custom-scroll::-webkit-scrollbar-track {
  background: #ffffff;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background: linear-gradient(90deg, #4b6cb7 0%, #293f6a 116.11%);
  border-radius: 5px;
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
  background: #293f6a;
}

/* Firefox */
.custom-scroll {
  scrollbar-width: thin;
  scrollbar-color: #4b6cb7 #ffffff; /* thumb color, track color */
}

/* Microsoft Edge (Chromium-based) */
.custom-scroll {
  scrollbar-color: #4b6cb7 #ffffff; /* thumb color, track color */
}

.text-area-input {
  padding-top: 0.3rem !important;
}

.width-sidebar-mobile {
  width: 260px;
}

.custom-width {
  max-width: 35rem;
}
.custom-width-min {
  min-width: 35rem;
  overflow: hidden !important;
}

.table-col-min-width {
  min-width: 230px !important;
}

.absoute-support-plan {
  position: absolute !important;
  min-width: 70%;
  left: 50% !important;
  top: 15% !important;
  transform: translate(-50%) !important;
}

.absoute-appointments {
  position: absolute !important;
  min-width: 45%;
  max-width: 100%;
  left: 50% !important;
  top: 5% !important;
  transform: translate(-50%) !important;
}

/* popover */

.popover__wrapper {
  position: relative;
  margin-top: 1.5rem;
  display: inline-block;
}
.popover__content {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  transform: translate(0, 10px);
  background-color: #bfbfbf;
  padding: 1.5rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  width: auto;
}
.popover__content:before {
  position: absolute;
  z-index: -1;
  content: "";
  right: calc(50% - 10px);
  top: -8px;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #bfbfbf transparent;
  transition-duration: 0.3s;
  transition-property: transform;
}
.popover__wrapper:hover .popover__content {
  z-index: 10;
  opacity: 1;
  visibility: visible;
  transform: translate(0, -20px);
  transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}
.popover__message {
  text-align: center;
}
.appearance-no[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
.errorBorder {
  border: 1px solid #ff0000b5 !important;
}

.errorBorderForSelect {
  border: 1px solid red !important;
  border-radius: 6px !important;
}

.errorMessage {
  font-size: 13px;
  color: #ff0000b5;
  margin-left: 5px;
}
input.border {
  border-color: hsl(0, 0%, 70%);
}

.signIn-input-error {
  border-color: #fda29b !important;
}

.signIn-input {
  border-color: #4b6cb7 !important;
}

/* ======================== Number input appearance ================= */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.activeBreadCrumb {
  font-weight: bold;
}

.caseNotesContainer {
  height: 533px;
}

.applicantNotesContainer {
  height: 390px;
}

.supportTeam {
  height: 100%;
  max-height: 780px;
  padding-right: 0px;
}

.absoute-Human-Body {
  position: absolute !important;
  min-width: 50%;
  left: 50% !important;
  top: 5% !important;
  transform: translate(-50%) !important;
}

.css-2613qy-menu {
  height: 50px;
}

.fullScreenHeight {
  height: 100vh !important;
}

.allApplicantHeight {
  height: 659px;
}

.deactiveDot {
  font-size: xx-large;
  left: 10%;
  position: absolute;
  bottom: 45%;
}

.profile-bg-hover:hover span span span span {
  color: rgb(17 24 39) !important;
}

.table-header-sticky {
  position: sticky !important;
  top: 75px !important;
}
.table-header-sticky-2 {
  position: sticky !important;
  top: 60px !important;
}

.tooltip-progressbar {
  visibility: hidden !important;
  position: absolute !important;
  overflow: hidden !important;
  position: fixed !important;
}

.has-tooltip-progress:hover .tooltip-progressbar {
  visibility: visible !important;
  z-index: 9999 !important;
  position: fixed !important;
  overflow: hidden !important;
}

.css-1s2u09g-control {
  background-color: white !important;
}

.css-1s2u09g-control,
.css-1insrsq-control {
  padding: 0 8px !important;
}

.css-1s2u09g-control .css-1hwfws3 {
  font-size: 14px !important;
}

.css1-pahdxg-control .css-12jo7m5,
.css-1pahdxg-control:hover .css-12jo7m5,
.css-1pahdxg-control:active .css-12jo7m5,
.css-1s2u09g-control:focus .css-12jo7m5,
.css-1pahdxg-control:focus.css-12jo7m5 {
  font-size: 14px !important;
}

.css-1hwfws3,
.css-1wy0on6,
.css-g1d714-ValueContainer,
.css-1pahdxg-control,
.css-319lph-ValueContainer {
  padding: 0 8px !important;
}

.css-1hwfws3,
.css-1wy0on6,
.css-g1d714-ValueContainer {
  font-size: 14px !important;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-wrap: nowrap !important;
}
.css-319lph-ValueContainer {
  line-height: 24px;
}
.css-12jo7m5,
.css-14el2xx-placeholder {
  font-size: 14px !important;
  text-align: start !important;
}
/* //pagination */
ul.pagination {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-bottom: 0;
  margin-right: 50px;
}
.pagination li {
  width: 30px;
  height: 30px;
  background: #f2f2f2;
  border-radius: 10px;
  text-align: center;
  margin-right: 5px;
}
.pagination li.active {
  /* background: #49d360; */
  background: rgb(126 34 206);
  border: 1px solid rgb(126 34 206);
  text-align: center;
}
.pagination li > a {
  font-size: 12px;
  color: #000 !important;
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.pagination li.active > a {
  color: #ffffff !important;
  position: relative;
}
.pagination li.previous a::before {
  content: "";
  background-image: url("../src/assets/left-arrow-icon.png");
  background-size: 8px 8px;
  width: 8px;
  height: 8px;
  display: inline-block;
}
.pagination li.next a::before {
  content: "";
  background-image: url("../src/assets/right-arrow-icon.png");
  background-size: 8px 8px;
  width: 8px;
  height: 8px;
  display: inline-block;
}
.swal-modal {
  width: 512px;
  padding-block: 10px;
}
.swal-modal .swal-title {
  color: rgb(17 24 39);
  font-size: 18px;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0;
  padding: 1rem 1.5rem;
}
.swal-text {
  color: rgb(107, 114, 128);
}

.swal-button--cancel {
  border: 1px solid rgb(209, 213, 219);
  background-color: #ffffff;
}
.swal-button.swal-button--confirm {
  background: linear-gradient(90deg, #4b6cb7 0%, #293f6a 116.11%);
}
.swal-button.swal-button--confirm:hover {
  background: linear-gradient(30deg, #4b6cb7 0%, #293f6a 116.11%);
}
.swal-button.swal-button--confirm.swal-button--danger {
  background: rgb(220, 38, 38);
}
.swal-button.swal-button--confirm.swal-button--danger:hover {
  opacity: 0.9;
}

div:not(.custom-scroll)::-webkit-scrollbar {
  display: none;
}

.sectionInputViewPrint {
  display: none !important;
}
.schedule-custom-width {
  width: -webkit-fill-available;
}
.expandedFooter {
  height: calc(100% - 297px);
}
.notExpandedFooter {
  height: calc(100% - 167px);
}
@media print {
  .sectionInputViewPrint {
    display: block !important;
    height: 100% !important;
    min-height: 38px !important;
  }
  .pagebreak {
    page-break-before: always;
  }
}

.sticky-div {
  position: -webkit-sticky;
  position: sticky;
  top: 0; /* Stick to the top of the container or viewport */
  /* You can also use other values for top, bottom, left, or right as needed */
}

/* Styles for the error message */
.error-message {
  color: red;
  font-size: 13px;
  margin-left: 5px;
}

.inputWrapper__dropdown {
  z-index: 9999;
}

.react-time-input-picker-wrapper {
  display: flex;
  justify-content: center;
}

.borderRadiusX {
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
}

.react-time-input-picker-wrapper {
  width: 100% !important;
  font-size: 14px;
  height: 38px;
  background: #fff;
  margin: 0px !important;
  box-shadow: 0 1px 2px #1018280d;
  border: 1px solid #d0d5dd;
  border-radius: 6px;
  box-sizing: border-box;
  display: flex;
  justify-content: start;
  /* padding: 0 15px; */
  /* display: block; */
  /*color: hsl(0, 0%, 50%);*/
}

.inputWrapper:nth-child(2) {
  padding: 0 6px 0px 6px !important;
}

.inputWrapper:nth-child(1) {
  padding-right: 8px !important;
}

.cross-fade-enter .cross-fade-enter-active {
  padding: 0 0px 0 5px !important;
}

/* Styles for bulleted lists */
.ck-content ul {
  list-style: disc inside; /* Changes the bullet style to disc */
  margin-left: 20px; /* Adds left margin */
  padding-left: 0; /* Removes any default padding */
}

/* Styles for numbered lists */
.ck-content ol {
  list-style: decimal inside; /* Changes the numbering style to decimal */
  margin-left: 20px; /* Adds left margin */
  padding-left: 0; /* Removes any default padding */
}

/* Additional list item spacing */
.ck-content ul li,
.ck-content ol li {
  margin-bottom: 5px; /* Adds space between list items */
}

.ck-content h1 {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 0.5em;
}

.ck-content h2 {
  font-size: 1.75em;
  font-weight: bold;
  margin-bottom: 0.5em;
}

.ck-content h3 {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 0.5em;
}

@keyframes blinkText {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

.blinkText {
  animation: blink 1s infinite;
  color: #000;
}

.select-basic-single .css-1hwfws3 {
  white-space: normal;
}
.select-basic-single .css-1uccc91-singleValue {
  overflow: visible;
  display: block;
}

.input-without-css {
  background-color: transparent;
  border: none;
}

.input-without-css:active {
  border: none !important;
  background-color: transparent;
}

.customDotClass > li {
  width: 45px !important;
  margin-top: 0.25rem !important;
}

.slick-dots {
  bottom: -50px !important;
}


.gray-scroll::-webkit-scrollbar {
  width: 6px; /* Adjust the width for a slim appearance */
}

.gray-scroll::-webkit-scrollbar-track {
  background: transparent; /* Transparent track background */
}

.gray-scroll::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, #ccc 0%, #888 100%); /* Gray gradient for the thumb */
  border-radius: 10px; /* Rounded edges for the scrollbar thumb */
}

.gray-scroll::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(180deg, #bbb 0%, #666 100%); /* Darker gray on hover */
}

/* Firefox */
.gray-scroll {
  scrollbar-width: thin; /* Thin scrollbar */
  scrollbar-color: #ccc transparent; /* Thumb and track colors */
}

/* Microsoft Edge (Chromium-based) */
.gray-scroll {
  scrollbar-color: #ccc transparent; /* Thumb and track colors */
}
