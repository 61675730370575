.notificatio-height{
    max-height: 500px;
    overflow-y: auto;
    
    
}
/* width */
.notificatio-height::-webkit-scrollbar {
    width: 8px;
    border-radius: 2rem;
}

/* Track */
.notificatio-height::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 2rem;
}

/* Handle */
.notificatio-height::-webkit-scrollbar-thumb {
    background: rgb(201, 201, 201); 
    border-radius: 2rem;
  }
  
  /* Handle on hover */
  .notificatio-height::-webkit-scrollbar-thumb:hover {
    background: rgb(146, 146, 146); 
  }