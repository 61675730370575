*,
::before,
::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.bloc-tabs {
  display: flex;
}
.tabs {
  /* padding: 15px; */
  text-align: center;
  margin: 0 1rem;
  /* background: rgba(128, 128, 128, 0.075); */
  cursor: pointer;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.274); */
  box-sizing: content-box;
  position: relative;
  outline: none;
}
.tabss {
  /* padding: 15px; */
  text-align: center;
  /* margin: 0 1rem; */
  /* background: rgba(128, 128, 128, 0.075); */
  cursor: pointer;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.274); */
  box-sizing: content-box;
  position: relative;
  outline: none;
}
.tabs:not(:last-child) {
  /* border-right: 1px solid rgba(0, 0, 0, 0.274); */
}

.active-tabs {
  /* background: white; */
  border-bottom: 1px solid transparent;
}

.active-tabs::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 2px;
  background: white;
}

button {
  border: none;
}
.content-tabs {
  flex-grow: 1;
}
.content {
  background: white;
  padding: 20px;
  width: 100%;
  height: 100%;
  display: none;
}
.content h2 {
  padding: 0px 0 5px 0px;
}
.content hr {
  width: 100px;
  height: 2px;
  background: #222;
  margin-bottom: 5px;
}
.content p {
  width: 100%;
  height: 100%;
}
.active-content {
  display: block;
}

.slide {
  transition: height 0.4s ease;
  padding-left: 15px;
  font-size: inherit;
}
.hover-child {
  display: none;
}
.hover-parent:hover > .hover-child {
  display: block;
}
