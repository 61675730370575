@keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }
  
  .blink {
    animation: blink 1s infinite;
    color: red;
  }
  