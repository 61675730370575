.text-slider {
    display: flex;
    flex-direction: column;
    height: 200px; /* Set the desired height */
    overflow: hidden;
  }
  
  .text-item {
    transition: transform 0.5s ease-in-out;
    transform: translateY(100%);
    opacity: 0;
    text-align: center;
    font-size: 24px;
    padding: 10px;
  }
  
  .text-item.active {
    transform: translateY(0);
    opacity: 1;
  }
  .description-content-system-news a {
    color: blue !important;
    text-decoration: underline !important;
  }