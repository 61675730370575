.PhoneInputInput{
    border-color: 1px solid #D0D5DD;
    border-radius: 0px 8px 8px 0px;
    background: #FFFFFF;
    width: 327px;
    height: 44px;
}
.registerInput{
    border-color: 1px solid #D0D5DD;
    border-radius: 8px 0px 0px 8px;
    background: #FFFFFF;
}

.swal-title{
    text-align: start;
}
.swal-text{
    text-align: start;
    padding-left: 22px;
    /* position: relative; */
}