.meter {
  background: rgb(241, 241, 241);
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
  display: block;
  height: 10px;
  margin-bottom: 10px;
  padding: 2px;
  position: relative;
  width: 100%;
}
.meter > span {
  display: block;
  height: 100%;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  /* background-color: #2bc253;
  background-image: linear-gradient(to top, #2bc253 37%, #54f054 69%); */
  box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3) inset 0 -2px 6px rgba(0, 0, 0, 0.4);
  position: relative;
  overflow: hidden;
  transition: width 2s ease-out;
}
.green > span {
  /* background-color: #ff4500;
  background-image: linear-gradient(to bottom, #ffa500, #ff4500); */
  background-color: #5f9ea0;
  /* background-color: rgb(243 244 246 / var(--tw-bg-opacity)); */
}
.red > span {
  background-color: #f0a3a3;
  background-image: linear-gradient(to bottom, #f0a3a3, #f42323);
}
.cadetblue > span {
  background: linear-gradient(90deg, #4b6cb7 0%, #293f6a 116.11%);
  /* background-color: #5f9ea0; */
  /* background-color: rgb(243 244 246 / var(--tw-bg-opacity)); */
  /* background-image: linear-gradient(to bottom, #0ff, #1e90ff); */
}

.progressBarCount {
  margin-top: 3px;
  font-weight: bold;
}
