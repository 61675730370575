#message {
  display: none;
  background: #f1f1f1;
  color: #000;
  position: relative;
  padding: 20px;
  margin-top: 10px;
}

#message p {
  padding: 3px 35px;
  font-size: 18px;
}

/* Add a green text color and a checkmark when the requirements are right */
.valid {
  color: #000;
}

.valid-letters {
  font-weight: 700;
  font-size: 18px;
  color: green;
}

.invalid-letters {
  font-weight: 700;
  font-size: 18px;
  color: #ff0000;
}

.valid:before {
  position: relative;
  left: -35px;
  color: green;
  content: "✔";
}

/* Add a red text color and an "x" when the requirements are wrong */
.invalid {
  color: #000;
}

.invalid:before {
  position: relative;
  left: -35px;
  color: #ff0000;
  content: "✖";
}

/* Floating label */

.did-floating-label-content {
  /* position: relative; */
  margin-bottom: 20px;
}
.did-floating-label {
  color: #667085;
  font-size: 13px;
  font-weight: normal;
  pointer-events: none;
  padding: 0 0;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  line-height: 1.9;
}
.did-floating-input, .did-floating-select {
  font-size: 14px;
  display: block;
  width: 100%;
  height: 38px;
  padding: 0 15px;
  background: #fff;
  /*color: hsl(0, 0%, 50%);*/
  box-shadow: 0 1px 2px #1018280d;
  border: 1px solid #d0d5dd;
  border-radius: 6px;
  box-sizing: border-box;
}
.did-floating-input:focus,
.did-floating-select:focus {
  outline: none;
  border: 1px solid #4b6cb7;
  background-color: #4b6bb710;
}
.did-floating-input:focus ~ .did-floating-label,
.did-floating-select:focus ~ .did-floating-label {
  top: -10px;
  font-size: 13px;
}

.did-floating-select {
  padding-right: 2rem;
}

select.did-floating-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
select.did-floating-select::-ms-expand {
  display: none;
}
.did-floating-input:not(:placeholder-shown) ~ .did-floating-label {
  top: -8px;
  font-size: 13px;
}
.did-floating-select:not([value=""]):valid ~ .did-floating-label {
  top: -8px;
  font-size: 13px;
}
.did-floating-select[value=""]:focus ~ .did-floating-label {
  top: 11px;
  font-size: 13px;
}
.did-floating-select:not([multiple]):not([size]) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6'%3E%3Cpath id='Path_1' data-name='Path 1' d='M371,294l4,6,4-6Z' transform='translate(-371 -294)' fill='%23003d71'/%3E%3C/svg%3E%0A");
  background-position: right 15px top 50%;
  background-repeat: no-repeat;
}
.did-error-input .did-floating-input,
.did-error-input .did-floating-select {
  border: 2px solid #9d3b3b;
  color: #9d3b3b;
}
.did-error-input .did-floating-label {
  font-weight: 600;
  color: #9d3b3b;
}
.did-error-input .did-floating-select:not([multiple]):not([size]) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6'%3E%3Cpath id='Path_1' data-name='Path 1' d='M371,294l4,6,4-6Z' transform='translate(-371 -294)' fill='%239d3b3b'/%3E%3C/svg%3E%0A");
}
.input-group {
  display: flex;
}
.input-group .did-floating-input {
  border-radius: 0 4px 4px 0;
  border-left: 0;
  padding-left: 0;
}
.input-group-append {
  display: flex;
  align-items: center;
}
/* margin-left:-1px;
*/
.input-group-text {
  display: flex;
  align-items: center;
  font-weight: 400;
  height: 34px;
  color: #323840;
  padding: 0 5px 0 20px;
  font-size: 12px;
  text-align: center;
  white-space: nowrap;
  border: 1px solid #3d85d8;
  border-radius: 4px 0 0 4px;
  border-right: none;
}

