.css-qbdosj-Input input:focus,
.css-166bipr-Input input:focus,
.css-vw0k input:focus {
  box-shadow: none !important;
}
.css-26l3qy-menu {
  height: "50px";
}
select > ul {
  overflow: auto;
}

/* .scrollOptions .css-2613qy-menu {
  overflow: auto;
  overflow-y: scroll;
} */

.addDisabledBgColor .css-1insrsq-control {
  background-color: #4b6bb710 !important;
}

.css-1insrsq-control,
.css-lr9fot-singleValue {
  font-size: 14px !important;
}

.css-lr9fot-singleValue {
  color: black !important;
}

.css-1s2u09g-control {
  font-size: 14px;
  border-radius: 6px !important;
  /* border-color: red; */
  border-color: hsl(0, 0%, 70%);
  /*padding: 0 0 0 10px !important;*/
}

.css-1s2u09g-control {
  background-color: white !important;
  font-size: 14px;
  border-radius: 6px !important;
  /* border-color: red !important; */
  /*padding: 0 0 0 10px !important;*/
}

.css-qc6sy-singleValue {
  color: black !important;
  font-size: 14px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
}

.css-1s2u09g-control > div {
  overflow: auto;
}

.css-6j8wv5-Input > input[type="text"]:focus {
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
}
.css-ackcql input,
.css-ackcql input:active,
.css-ackcql input:focus,
.css-1pndypt-Input input,
.css-1pndypt-Input input:active,
.css-1pndypt-Input input:focus {
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
}
/* .css-6j8wv5-Input  >
input[type='text'] {
  box-shadow: 0px !important;
  outline: none !important;
  border: none !important;
} */

.css-1pahdxg-control {
  box-shadow: none !important;
}

.css-ipjlip-ValueContainer {
  /* text-align: center !important; */
  align-items: center !important;
  /* height: 38px !important; */
}
